import stringify from 'json-stringify-safe';
import { MessageCode } from '../types/common';

export class PurchaseLimitExceededError extends Error {
  constructor() {
    super('Purchase limit exceeded.');
    Object.setPrototypeOf(this, PurchaseLimitExceededError.prototype);
  }
}

export class CheckoutDataDecodeError extends Error {
  constructor(details: string) {
    super(details);
    Object.setPrototypeOf(this, CheckoutDataDecodeError.prototype);
  }
}

export class StatusDataDecodeError extends Error {
  constructor(details: string) {
    super(details);
    Object.setPrototypeOf(this, StatusDataDecodeError.prototype);
  }
}

export class IntegrationDataDecodeError extends Error {
  constructor(details: string) {
    super(details);
    Object.setPrototypeOf(this, IntegrationDataDecodeError.prototype);
  }
}

export class AppSectionParamsDecodeError extends Error {
  constructor(details: string) {
    super(details);
    Object.setPrototypeOf(this, IntegrationDataDecodeError.prototype);
  }
}

export function errorToMessage(e: Error): MessageCode {
  if (e instanceof PurchaseLimitExceededError) {
    return MessageCode.PURCHASE_LIMIT_ERROR;
  }
  if (e instanceof CheckoutDataDecodeError) {
    return MessageCode.CHECKOUT_PAYLOAD_DECODE_FAILED;
  }
  if (e instanceof StatusDataDecodeError) {
    return MessageCode.STATUS_PAYLOAD_DECODE_FAILED;
  }
  if (e instanceof IntegrationDataDecodeError) {
    return MessageCode.INTEGRATION_DATA_DECODE_FAILED;
  }
  return MessageCode.UNKNOWN_ERROR;
}

export function messageToText(message: MessageCode): string {
  switch (message) {
    case MessageCode.PURCHASE_LIMIT_ERROR:
      return 'pp.1-purchase-plan-error';
    case MessageCode.PLAN_NOT_FOUND:
      return 'pp.checkout-missing-plan';
    case MessageCode.CHECKOUT_DEMO:
      return 'pp.demo-checkout-message';
    default:
      return 'pp.generic-purchase-error';
  }
}

export function isMessageInformational(message: MessageCode) {
  switch (message) {
    case MessageCode.CHECKOUT_DEMO:
      return true;
    default:
      return false;
  }
}

export const toError = (e: unknown): Error =>
  e instanceof Error ? e : new Error(typeof e === 'string' ? e : stringify(e));

export const getApplicationErrorCode = (e: any) => e?.response?.data?.details?.applicationError?.code;
