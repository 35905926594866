import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { PlanListInteractions } from '../../../types/PlanListFedops';
import List, { ListProps } from '../../PackagePicker/Widget/List';
import { EmptyStateProps } from '../../PackagePicker/Widget/List/EmptyState';
import { PlanListSettingsAdapter } from '../PlanListSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

const Widget: FC<WidgetProps<ListProps>> = (props) => {
  const settings = useSettings();
  const settingsAdapter = React.useMemo(() => new PlanListSettingsAdapter(settings, settingsParams), [settings]);
  const emptyState: EmptyStateProps = { isInWidget: true };
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsAdapterProvider adapter={settingsAdapter}>
        <List
          {...props}
          dataHook="PlanList-wrapper"
          interactionEnd={PlanListInteractions.WidgetLoaded}
          emptyState={emptyState}
        />
      </SettingsAdapterProvider>
    </StylesParamsProvider>
  );
};

export default Widget;
