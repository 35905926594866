import React from 'react';
import { Pricing } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { useTranslation, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS, NO_BREAK_SPACE, VIEWER_HOOKS } from '../../../../../constants';
import { getPeriodLabel, getPeriodLabelV2 } from '../../../../../utils';
import { st, classes } from './Recurrence.st.css';

export interface RecurrenceProps {
  pricing?: Pricing;
  recurringPlansExist: boolean;
  highlighted: boolean;
}

export const Recurrence: React.FC<RecurrenceProps> = ({ pricing, recurringPlansExist, highlighted }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const isAdditionalPaymentPeriodsEnabled = experiments.enabled(EXPERIMENTS.ADDITIONAL_PAYMENT_PERIODS);

  const periodLabel = () => {
    if (isAdditionalPaymentPeriodsEnabled) {
      return getPeriodLabelV2(pricing?.subscription, t);
    }

    return getPeriodLabel(pricing?.subscription, t);
  };

  if (!recurringPlansExist) {
    return null;
  }

  return (
    <span data-hook={VIEWER_HOOKS.PLAN_RECURRENCE} className={st(classes.frequency, { highlighted, mobile: isMobile })}>
      {pricing?.subscription ? periodLabel() : NO_BREAK_SPACE}
    </span>
  );
};
