import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { VIEWER_HOOKS } from '../../../../../constants';
import { useStylesParams } from '../../../../../hooks';
import { st, classes } from './PlanName.st.css';

interface Props {
  name: string;
  highlighted: boolean;
}

export const PlanName: React.FC<Props> = ({ name, highlighted }) => {
  const { isMobile } = useEnvironment();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanName = styles.get(stylesParams.showPlanName);

  if (!showPlanName) {
    return null;
  }

  return (
    <h2
      data-hook={VIEWER_HOOKS.PLAN_NAME}
      aria-describedby={highlighted ? 'ribbon' : undefined}
      className={st(classes.planTitle, { highlighted, mobile: isMobile })}
    >
      {name}
    </h2>
  );
};
