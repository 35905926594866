export interface IEvents {
  tabState: TabState;
  areMobileSettingsOpened: boolean;
  reset: undefined;
}

export const SETTINGS_EVENTS: { [index: string]: keyof IEvents } = {
  ARE_MOBILE_SETTINGS_OPENED: 'areMobileSettingsOpened',
  TAB_STATE: 'tabState',
  RESET: 'reset',
};

export enum TabState {
  REGULAR = 'regular',
  HIGHLIGHTED = 'highlighted',
}
