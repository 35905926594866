import React, { useCallback, useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { VIEWER_HOOKS } from '../../../../../constants';
import { useDynamicTagline } from '../../../../../hooks';
import { st, classes } from './Tagline.st.css';

interface Props {
  planId: string;
  description: string;
  highlighted: boolean;
}

export const Tagline: React.FC<Props> = ({ planId, description, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { taglineHeights, addTagline, removeTagline } = useDynamicTagline();
  const oldRef = useRef<any>(null);
  const shouldUseDynamicTagline = !isMobile;

  const setRef = useCallback(
    (node) => {
      if (node && oldRef.current !== node) {
        addTagline(planId, node);
      }
      if (oldRef.current) {
        removeTagline(planId, oldRef.current);
      }
      oldRef.current = node;
    },
    [planId, addTagline, removeTagline],
  );

  return (
    <div
      data-hook={VIEWER_HOOKS.PLAN_TAGLINE}
      className={st(classes.tagline, { highlighted, mobile: isMobile })}
      style={shouldUseDynamicTagline ? { height: taglineHeights[planId] } : {}}
    >
      <div ref={shouldUseDynamicTagline ? setRef : undefined}>{description}</div>
    </div>
  );
};
