import React from 'react';
import { Button, Text, TextTypography } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { VIEWER_HOOKS } from '../../../../../constants';
import { st, classes } from './EmptyState.st.css';

export type EmptyStateProps = { isInWidget: true } | { isInWidget: false; onClick: () => void };

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.wrapper, { widget: props.isInWidget })} data-hook={VIEWER_HOOKS.EMPTY_STATE}>
      <Text className={classes.title} typography={TextTypography.smallTitle}>
        {t('pp.empty-state.title')}
      </Text>
      <Text className={classes.subtitle} typography={TextTypography.runningText}>
        {t('pp.empty-state.subtitle')}
      </Text>
      <div>
        {!props.isInWidget && (
          <Button
            data-hook={VIEWER_HOOKS.EMPTY_STATE_BUTTON}
            onClick={props.onClick}
            upgrade
            className={classes.button}
          >
            {t('pp.empty-state.button')}
          </Button>
        )}
      </div>
    </div>
  );
};
